p {
    margin: 0;
    padding: 0;
}
.analytics-main-container {
    background-color: #1e2631;
    border-radius: 4px;
    /* min-width: 1233px; */
    /* width: 1233px; */
    min-width: 924px;
}
.analytics-header {
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 12px;
    padding: 12px 0px;
    padding-bottom: 10px;
}
.analytics-data-container {
    display: flex;
    justify-content: space-between;
    margin-right: 210px;
    margin-left: 12px;
    margin-bottom: 16px;
}
.analytics-data-container h2 {
    font-size: 24px;
    font-weight: 400;
    color: #00aeef;
}
.analytics-data-container p {
    margin: 0px;
    font-size: 12px;
}
.analytics-hr {
    border: 0.5px solid #3c485a;
    width: 95%;
    margin-bottom: 20px;
}
.analytics-hr-container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.headers-data {
    display: flex;
    justify-content: space-between;
    width: 145px;
}
.headers-data p {
    font-size: 10px;
    font-weight: 400;
}
.graph-container-two {
    display: grid;
    align-items: center;
    grid-template-columns: 5fr 1fr;
    gap: 24px;
    /* margin: 25px 0px; */
    margin-top: 24px;
}
