.dashboard-custom-date-picker {
    width: 266px;
    background-color: #1e2631;
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 4px;
}
.dates-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
}
.dates-wrapper div {
    font-size: 12px;
    font-weight: 400;
}
.range-picker-pop-up > div > div {
    background-color: #141922 !important;
}
.ant-picker-presets ul {
    color: #ffffff;
}

.ant-picker-content thead tr th {
    color: white !important;
}
/* .ant-picker-cell-in-view.ant-picker-cell-range-start,
.ant-picker-cell-in-view.ant-picker-cell-range-hover {
    color: red !important;
    background-color: black !important;
} */
