.total-botag-sales-container {
    min-width: 320px;
    background-color: #1e2631;
    border-radius: 4px;
    padding: 16px;
    height: 190px;
}

.total-botag-heading {
    font-size: 12px;
    font-weight: 400;
    padding-bottom: 16px;
}
.total-botag-graph-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.total-botag-graph-container div h2 {
    font-size: 24px;
    font-weight: 400;
    color: #00aeef;
    padding-bottom: 12px;
}
.total-botag-sales-hr {
    border: 0.5px solid #3c485a;
    width: 95%;
    margin: 16px 0px;
}
.total-botag-footer {
    display: flex;
    justify-content: space-between;
    margin-right: 130px;
}
