.order-preview-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--text-icons-gray-100);

    position: sticky;
    top: 0%;
    background-color: #1c2530;
    z-index: 999;
    padding-top: 32px;
    padding-bottom: 12px;
}
.supplier-info {
    display: flex;
    gap: 142px;
    color: var(--text-icons-gray-100);
    background-color: #0f141b;
    padding: 15px;
    margin-top: 7px;
    position: relative;
}
.supplier-info-secondary-item {
    justify-content: space-between;
}
.supplier-info-item {
    justify-content: space-between;
}
.key-value {
    margin-right: 233px;
    margin-left: -248px;
}
.dropdown-key-val {
    position: absolute;
    right: 21px;
    top: 25px;
}

.supplier-main-key {
    height: 14px;
    width: 203.6px;
}
.supplier-key-value {
    font-size: 12px;
    line-height: 18px;
}
.key-value-container {
    gap: 72px;
    /* margin-right: 67px; */
    display: flex;
    color: var(--text-icons-gray-100);
}
.supplier-key-value span {
    color: var(--text-icons-blue-500);
}
.search-container {
    display: flex;
    align-items: center;
    gap: 5px;
}
.search-container label {
    margin: 0px;
    color: var(--text-icons-gray-100);
}
.search-container input {
    background-color: #2f3a4a;
    border: none;
    color: var(--text-icons-gray-100);
}
.table-export-buttons.order-preview {
    display: flex;
    justify-content: end;
    gap: 10px;
    margin: 20px 0px;
}
.supplier-main-all-div {
    width: 233.6px;
    height: 71px;
    font-weight: 400;
    font-size: 12px;
    /* line-height: 14.06px; */
    letter-spacing: 1.25%;
    margin-top: 6px;
}
.download-btn {
    background-color: #10141b;
    border: 1px solid #ff3636;
    width: 120px;
    height: 35px;
    color: #ffffff;
    font-size: 10px;
    font-weight: 400;
    line-height: 11.72px;
}

.dropdown {
}
.dropdown-menu {
}
.open > .dropdown-menu {
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: #111620;
    min-width: 141px;
    padding: 8px 3px;
    font-weight: 400;
    font-size: 12px;
    text-decoration: none;
    line-height: 14.06px;
    border-radius: 4px;

    margin-left: -19px;
}
.dropdown-item {
    text-decoration: none !important;
    color: #ffffff;
    padding: 5px 8px;
    border-radius: 4px;
    margin: -3px 8px;
}
.dropdown-item:hover {
    background-color: #1e2631;
    color: #ffffff;
}
.supplier-main-all-div-main {
    width: 233.6px;

    font-weight: 500;
    font-size: 10px;
    line-height: 5px;
    letter-spacing: 1.25%;
}
.order-preview-drop-down {
    color: #ffffff;
}
.order-preview-drop-down:hover {
    color: #ffffff;
}
@media screen and (max-width: 1200px) {
    .supplier-info {
        gap: 10px;
        margin-top: 10px;
    }
    .key-value-container {
        gap: 100px;
    }
    .supplier-key-value {
        font-size: 6px;
        line-height: 10px;
    }
    .supplier-heading {
        font-size: 8px;
    }
}
@media screen and (max-width: 615px) {
    .table-export-buttons.order-preview {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
.supplier-info {
    gap: 100px;
}
@media screen and (max-width: 615px) {
    .table-export-buttons.order-preview {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .supplier-info {
        gap: 50px;
    }
}
/* @media screen and (min-width: 1587px) {
    .table-export-buttons.order-preview {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .supplier-info {
        .supplier-info {
            gap: 63px;
        }
    } */
