.dashboard-map-container {
    /* min-width: 395px; */
    width: 395px;
    height: 427px;
    background-color: #1e2631;
    border-radius: 4px;
    padding: 16px;
    position: relative;
}
.map-header {
    font-size: 12px;
    color: #d1d1d1;
    font-weight: 400;
}
.map-wrapper {
    position: relative;
    top: 29px;
    left: -35px;
}
.map-test-images {
    position: absolute;
    height: 200px;
}
.sweden {
    top: 22px;
    left: 70px;
}
.norwary {
    top: 58px;
    left: 139px;
}
.images-container {
    position: absolute;
    bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 6px;
}
.flag-images-test {
    width: 37px;
}
