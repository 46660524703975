/* #region MAIN APP STYLING */
body,
#root {
    margin: 0;
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--text-icons-gray-100);
    background: var(--backgrounds-lines-n-600);
    font-size: 14px;
    /* overflow: hidden; */
    height: auto;
    position: relative;
}

div.AppContainer.route-pre-enrollment {
    /* height: 1230px; */
    overflow: hidden;
}

div.AppContainer.route-pre-enrollment-invited {
    overflow: hidden;
}

html {
    font-size: 16px; /* RULE SET: 1rem = 16px */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h6,
h5,
h4,
h3,
h2,
h1 {
    color: var(--text-icons-gray-100);
}

label {
    margin-bottom: unset; /* disable bootstrap default margin */
    font-weight: unset; /* disable bootstrap default font-weight */
    cursor: unset;
}

::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-corner {
    background-color: transparent !important;
}

/*::-webkit-scrollbar-thumb {
    background-color: darkgrey ;
    outline: 1px solid slategrey;
}*/

/* Scrollbar colors */
::-webkit-scrollbar-thumb {
    background-color: var(--backgrounds-lines-n-100) !important;
}

.scroll-bar-container::-webkit-scrollbar-button {
    display: none;
}

.nav-item .nav-link {
    color: var(--text-icons-gray-100);
    display: block;
    font-size: 16px;
}
/* #endregion */

/* #region MAIN APP CONTAINERS AND WRAPPERS */
/* #myscreen{
    min-width: 800px;
} */

.main-wrapper {
    display: flex;
    width: 100%;
    height: calc(100dvh - 40px);
    min-width: 1024px; /* TABLET MINIMUM SIZE */
}

.footer-wrapper {
    width: 100%;
    height: 40px;
    box-shadow: 0px -2px 10px 0px rgba(17, 22, 32, 0.8);
    position: relative;
    z-index: 9999;
}

.navigation-container {
    display: flex;
    width: 220px;
    min-width: 220px;
    height: 100%;
    /* overflow-y: auto;
    overflow-x: hidden; */
    background-color: var(--backgrounds-lines-n-300);
    z-index: 10001;
}

.main-content {
    width: 100%;
    height: 100%;
    position: relative;
    min-width: 600px;

    display: flex;
    flex-direction: column;
}

.header-container {
    width: 100%;
    height: 70px;
    background-color: var(--backgrounds-lines-n-200);

    flex-shrink: 0;
}

.controlbar-container {
    width: 100%;
    height: 62px;
    background-color: var(--text-icons-gray-300);

    flex-shrink: 0;

    box-shadow: 0px 2px 10px 0px rgba(17, 22, 32, 0.8);
    position: relative;
    z-index: 9999;
}

.filterheader-container {
    flex-shrink: 0;
}

.router-container {
    width: 100%;
    /* background-color: darkviolet; */
    /* height: calc(100% - 200px); */
    flex-grow: 1;
    overflow-y: auto;

    /* scrollbar page shift fix (scrollbar width is 7px) */
    padding: 24px 17px 0px 24px;
    scrollbar-gutter: stable;
    /* padding-left: 24px;
    padding-right: 17px; */
}

/* .router-container {
    background-image: url("../src/assets/images/BOTag-Watermark.png");
    background-repeat: no-repeat;
    background-size: 360px;
    background-position: center;
}*/

.router-container {
    background-image: url("../src/assets/images/BOTag-Watermark2.png");
    background-repeat: no-repeat;
    background-size: 252px;
    background-position-x: calc(50%);
    background-position-y: calc(50% - 40px);
}

.content-wrapper {
    min-height: 100%;
}

/* #endregion */

/* #region TOASTS LAYOUT FROM REACT-TOASTIFY */

.Toastify__toast-container {
    width: 430px !important;
    z-index: 99999999 !important;
}

.Toastify__toast-theme--dark {
    /* background: var(--backgrounds-lines-n-200) !important; */
    background: #141922 !important;
}

.Toastify__toast-icon {
    -webkit-margin-end: 15px !important;
    margin-inline-end: 15px !important;
    width: 45px !important;
}

.Toastify__toast-icon img {
    width: 35px !important;
}
/* #endregion */

/* #region DATABALE STRUCTURE //TODO: TO BE PLACED INSIDE COMPONENT */
/*
button[aria-label="Expand Row"],
button[aria-label="Collapse Row"],
button[aria-label="Expand Row"]:hover,
button[aria-label="Collapse Row"]:hover {
    place-content: center;
    background-color: transparent !important;
    height: 48px;
}

.default-collapse-icon {
    border: 1px solid var(--backgrounds-lines-n-100);
    background-color: var(--backgrounds-lines-n-100);
    color: var(--text-icons-gray-200);
    width: 16px;
    height: 16px;
    text-align: center;
}

.tablestructure > div > div {
    width: 100%;
    background-color: var(--backgrounds-lines-n-400);
}

.tablestructure .btn.btn-success {
    background-color: var(--backgrounds-lines-n-400);
    border-color: var(--Buttons-All-Platforms-Gray-300);
    color: var(--text-icons-gray-100);
    font-family: Roboto;
    font-size: 10px;
    height: 35px;
    line-height: 35px;
    margin: 0 5px;
    text-transform: uppercase;
    width: 60px;
    padding: 0;
}

.tablestructure .exclamation-triangle {
    width: 20px;
}

.tablestructure .rdt_TableHeadRow {
    border-bottom: 1px solid var(--backgrounds-lines-n-100);
    color: var(--text-icons-gray-100) !important;
    font-size: 12px !important;
    font-weight: normal !important;
}

.rdt_TableBody .rdt_TableRow,
.rdt_TableHeadRow {
    color: var(--text-icons-gray-100) !important;
    font-family: Roboto !important;
    border-radius: 4px;
    margin: 4px 16px;

    padding-left: -15px;
}
.rdt_TableRow:nth-child(odd) {
    background-color: var(--backgrounds-lines-n-300);
}
.rdt_TableRow:hover {
    background-color: var(--backgrounds-lines-n-600);
}

.rdt_TableHeader {
    border-bottom: 1px solid var(--backgrounds-lines-n-100);
}
.rdt_TableBody,
.rdt_TableRow {
    border-bottom: none !important;
    width: calc(100% - 32px);
}
.menuDropDown .actionOption:hover {
    background-color: var(--backgrounds-lines-n-400) !important;
}

.tablestructure.sticky-action .rdt_TableHeadRow .rdt_TableCol:last-child,
.tablestructure.sticky-action .rdt_TableRow .rdt_TableCell:last-child {
    // position: sticky !important;
    // right: 0;
    // background-color: var(--backgrounds-lines-n-600);
    // min-width: 222px !important;
    // padding: 0 !important;
    place-content: end;
    max-width: 80px !important;
    // z-index: 99999;
}
.rdt_TableHeadRow .rdt_TableCol:nth-last-child(2),
.rdt_TableRow .rdt_TableCell:nth-last-child(2) {
    display: flex;
    justify-content: right !important;
    // margin-right: -60px;
    max-width: 50px;
}
.rd-table-header {
    visibility: visible !important;
}
.hxEqZG {
    margin-right: 10px;
}

.skeletonText {
    font-family: Roboto;
    font-size: 10px;
    font-weight: 500;
    line-height: 5px;
    letter-spacing: 0.0125em;
    text-align: left;
    color: var(--text-icons-gray-300);
}
.tablestructure.sticky-action .rdt_TableHeadRow .rdt_TableCol:last-child > div.rdt_tableCol_Sortable,
.tablestructure.sticky-action .rdt_TableRow .rdt_TableCell:last-child > div.rdt_tableCol_Sortable {
    place-content: center;
}
.rdt_TableCol {
    display: flex;
    align-items: center;
    justify-content: left;
}

.rdt_TableRow .rdt_TableCell:first-child {
    justify-content: center;
}
.rdt_TableRow .rdt_TableCell {
    font-size: 12px;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    z-index: 0 !important;
}

.rdt_TableHeadRow div[role~="columnheader"] > div {
    white-space: break-spaces;
}

.rdt_Pagination {
    color: var(--text-icons-gray-200) !important;
}

.rdt_ExpanderRow > .table.child-table {
    margin-bottom: 0px;
}

.table.child-table .nested-col {
    height: 48px;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
    border-top: 0px;
}

.__rdt_custom_sort_icon__ {
    margin-left: 5px;
    display: flex;
}

.__rdt_custom_sort_icon__ > i {
    font-size: 14px !important;
}

.rdt_Table {
    margin-bottom: 95px;
    background-color: var(--backgrounds-lines-n-400);
}
*/
/* #endregion */

/* #region SWEET ALERT CONFIRM DIALOG //TODO: WILL BE CREATED A NEW COMPONENT FOR DIALOG? */
.swal2-container.swal2-center > .swal2-popup {
    padding: 10px 20px !important;
}

.swal2-icon {
    margin: 10px auto 40px !important;
}

.swal2-actions {
    margin: 0 0 10px !important;
}

.swal2-html-container {
    font-size: 14px !important;
    line-height: normal;
    text-align: center;
    font-family: "Roboto" !important;
    font-weight: 400 !important;
    color: var(--text-icons-gray-100) !important;
    margin: 0 0 40px !important;
}

.swal2-styled.swal2-confirm {
    color: var(--text-icons-gray-100);
    font-size: 14px !important;
    font-family: "Roboto" !important;
    font-weight: 400 !important;
    text-transform: uppercase !important;
    width: 220px !important;
    height: 35px;
    margin: auto !important;
}

.swal2-styled.swal2-deny {
    color: var(--text-icons-gray-100);
    font-size: 14px !important;
    font-family: "Roboto" !important;
    font-weight: 400 !important;
    text-transform: uppercase !important;
    width: 220px !important;
    height: 35px;
    margin: 0 0 0px 20px !important;
}

.swal2-styled {
    padding: 5px !important;
}

.swal2-styled:hover {
    background-color: #3c7fc1 !important;
}

.swal2-styled.swal2-default-outline:focus {
    box-shadow: none !important;
}

/* #endregion */

/* #region ACTIVE BRAND/RETAIL/GROUP SELECTOR //TODO: TO BE DELETED */
.nav-item.dropdown.profile-open.active-brand-names .dropdown-menu a.dropdown-item {
    padding: 8px 12px;
    text-transform: capitalize;
    font-family: Roboto;
    display: block;
    font-size: 13px;
    white-space: nowrap;
    color: var(--text-icons-gray-100);
}

.nav-item.dropdown.profile-open.active-brand-names .dropdown-menu-lg,
.nav-item.dropdown.profileopen .dropdown-menu-lg {
    max-width: 260px;
    min-width: 190px;
    padding: 0;
    max-height: 180px;
    overflow-x: auto;
    background-color: var(--backgrounds-lines-n-100);
    border-radius: 12px;
    margin-top: 15px;
    left: 25px !important;
}

.dropdown-divider {
    border-top: 1px solid var(--backgrounds-lines-n-600);
    height: 0;
    margin: 0;
    overflow: hidden;
}

.dropdown-menu.dropdown-menu-lg.dropdown-menu-right::before {
    color: var(--backgrounds-lines-n-100);
    content: "\f0d8";
    font-family: "FontAwesome";
    font-size: 20px;
    margin-left: 40px;
    margin-top: -16px;
    position: fixed;
    top: auto;
}

/* #endregion */

/* #region DRAG AND DROP CSS //TODO: TO BE PLACED INSIDE COMPONENT */
.perfom-input.blank {
    border: 1px solid var(--backgrounds-lines-n-600);
    border-left: 5px solid var(--text-icons-orange-500);
}

.perfom-input {
    background: var(--Buttons-All-Platforms-Gray-500);
    border: 1px solid var(--App-Accent-Outline);
    border-radius: 5px;
    color: var(--text-icons-gray-200);
    font-size: 12px;
    font-style: italic;
    height: 40px;
    line-height: 25px;
    padding: 6px 12px;
    text-align: center;
}

.perfom-input .draggerdfield {
    color: var(--text-icons-gray-200);
    font-size: 13px;
    text-transform: uppercase;
}

.perfom-input.non-mondate {
    border: 1px solid var(--backgrounds-lines-n-600);
}

/* #endregion */

/* #region FILTER-SECTION CSS //TODO: TO BE DELETED */
.filter-section .form-control {
    width: 100%;
    border-color: var(--backgrounds-lines-n-100);
    background: var(--Buttons-All-Platforms-Gray-500);
    border-width: 1px;
    height: 40px;
    border-radius: 6px;
    max-width: 300px;
    font-style: italic;
    display: inline-flex;
    color: var(--text-icons-gray-100);
}

.filter-section .form-control::placeholder {
    color: var(--text-icons-gray-200);
}

.filter-section .form-control:hover,
.filter-section btn:hover {
    border-color: var(--App-Accent-Outline);
}

.filter-btn {
    width: 40px;
    height: 36px;
    font-size: 16px;
    background-color: var(--backgrounds-lines-n-400);
    border-color: var(--Buttons-All-Platforms-Gray-300);
    color: var(--text-icons-gray-100);
}

.filter-btn-with-label {
    display: inline-grid;
    width: auto;
    text-align: center;
    font-size: 10px;
    margin-left: 8px;
    position: relative;
}

.filter-btn-with-label > span {
    text-align: center;
    font-size: 10px;
    color: var(--text-icons-gray-200);
}

.filter-btn-with-label > .btn,
.filter-btn-with-label > .btn:hover,
.filter-btn-with-label.disabled > .btn,
.filter-btn-with-label.disabled > .btn:hover {
    opacity: 1;
    background-color: var(--backgrounds-lines-n-400) !important;
    color: var(--text-icons-gray-100);
    border-color: var(--Buttons-All-Platforms-Gray-300);
}

.filter-btn-with-label.disabled > .btn,
.filter-btn-with-label.disabled > .btn:hover {
    color: var(--text-icons-gray-300);
    cursor: not-allowed;
}

.filter-btn-with-label > .btn:hover,
.filter-btn-with-label.disabled > .btn:hover {
    border-color: var(--App-Accent-Outline);
}

.filter-section {
    display: flex;
}

.filter-section.right {
    float: right;
}

.filter-section > .selects {
    width: 290px;
    margin-right: 0px;
}

.filter-section > .selects > div {
    height: 40px;
}

.filter-section > p {
    margin-right: 15px;
    padding: 9px 0px;
    color: var(--text-icons-gray-100);
    font-family: "Roboto";
    font-size: 14px;
}

.filter-section span {
    color: var(--text-icons-gray-200);
}

.showItems .sc-hHLeRK,
.showItems .sc-hHLeRK.fhIruZ {
    display: block !important;
}

.filter-container {
    display: contents;
}

/* #endregion */

/* #region MAIN CSS CLASSES //TODO: SOME OF THESE CLASSES WILL BE REFACTORED INSIDE NEW COMPONENTS */
.border-radius {
    border-radius: 4px;
}

.status-dot {
    width: 8px;
    height: 8px;
    background: var(--text-icons-green-500);
    border-radius: 8px;
    margin-right: 8px;
}

.text-not-active,
.text-not-active a,
.text-not-active label,
.text-not-active span,
.text-not-active p,
span.disabled,
label.disabled {
    color: var(--text-icons-gray-300) !important;
}

.button-not-active,
.button-not-active .btn {
    color: var(--text-icons-gray-300) !important;
    cursor: not-allowed;
}

.error-input {
    border: 2px solid var(--text-icons-green-500) !important;
}

.font--small {
    font-size: 11px;
}

.font--smaller {
    font-size: 9px;
}

.small-legend-group {
    margin-top: 5px;
    display: flex;
}

.small-legend-field {
    display: flex;
    margin-bottom: 5px;
    margin-right: 10px;
}

.small-legend-field > .box {
    width: 7px;
    height: 7px;
    align-self: center;
    margin-right: 4px;
}

.small-legend-field > span {
    color: var(--text-icons-gray-200);
    font-size: 12px;
}

.main-btn,
.main-btn--small,
.main-btn--smaller {
    color: var(--text-icons-gray-100);
    background-color: var(--backgrounds-lines-n-400);
    border-color: var(--Buttons-All-Platforms-Gray-300);
    height: 36px;
    font-size: 10px;
    font-family: Roboto;
}

.main-btn[disabled],
.main-btn.disabled,
.main-btn--small[disabled],
.main-btn--small.disabled {
    color: var(--text-icons-gray-300) !important;
}

.main-btn:hover,
.main-btn:active,
.main-btn--small:active .main-btn:focus,
.main-btn--small:hover,
.main-btn--small:focus,
.main-btn--smaller:hover,
.main-btn--smaller:focus {
    color: var(--text-icons-gray-100);
    border-color: var(--App-Accent-Outline) !important;
}
.main-inner-btn {
    background-color: var(--App-Accent-Active) !important;
    border-color: var(--Buttons-All-Platforms-Gray-300) !important;
    color: var(--text-icons-gray-100) !important;
    font-size: 10px !important;
    padding: 10px 0px !important;
}

.main-btn--smaller {
    font-size: 10px;
    height: auto;
}

.main-btn {
    min-width: 110px;
    color: var(--text-icons-gray-100);
}
.main-inner-btn {
    min-width: 110px;
    color: var(--text-icons-gray-100);
}

.main-btn.btn.btn-success {
    background-color: var(--Buttons-All-Platforms-btn-action-green);
    border-color: var(--Buttons-All-Platforms-btn-action-green);
}

.main-btn.btn.btn-success:hover,
.main-btn.btn.btn-success:hover {
    background-color: var(--Buttons-All-Platforms-btn-action-green-hover);
    border-color: var(--Buttons-All-Platforms-btn-action-green-hover) !important;
    outline: none;
}

.main-btn.accent {
    background-color: var(--App-Accent-Outline);
}
.main-inner-btn.accent {
    background-color: var(--backgrounds-lines-n-400);
}
.main-btn.accent:hover,
.main-btn.accent:focus {
    background-color: var(--App-Accent-Outline);
    border-color: var(--App-Accent-Outline) !important;
    color: var(--text-icons-gray-100);
    outline: none;
}

.main-btn[disabled] {
    background-color: var(--backgrounds-lines-n-400) !important;
    border-color: var(--Buttons-All-Platforms-Gray-300) !important;
    color: var(--text-icons-gray-300) !important;
    cursor: not-allowed;
    opacity: 1;
}
.main-inner-btn[disabled] {
    background-color: var(--App-Accent-Active) !important;
    border-color: var(--Buttons-All-Platforms-Gray-300) !important;
    color: var(--text-icons-gray-100) !important;
    cursor: not-allowed;
    opacity: 1;
}

.BO-main-btn {
    display: flex;
    height: 35px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    background-color: var(--backgrounds-lines-n-600);
    color: var(--text-icons-gray-300);

    /* Button-Text-Large */
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.15px;
    text-transform: uppercase;
}

.BO-main-btn:focus,
.BO-main-btn:focus-visible {
    outline: none !important;
    color: var(--text-icons-gray-300);
}

.BO-main-btn:not(.disabled):hover {
    background-color: var(--backgrounds-lines-n-400);
    color: var(--text-icons-gray-100);
}

.BO-main-btn.disabled:hover {
    color: var(--text-icons-gray-300);
    border-color: var(--App-Accent-Outline);
}

/* #region PAGE CARD SECTIONS WITH MANDATORY AND LOCKED FIELDS */

.cardsection {
    background-color: var(--backgrounds-lines-n-400);
    font-family: Roboto;
    margin: 0 0 30px;
    border-radius: 4px;
}

.card-title {
    font-size: 14px;
    color: #ffffff;
    text-transform: uppercase;
    margin: 0;
    font-weight: 500;
}

.card-header {
    padding: 16px 16px;
    border-bottom: 1px solid var(--backgrounds-lines-n-100);
    height: 48px;
}

.card-body {
    padding: 22px 15px 25px;
}

.formcautions ul li {
    display: inline-block;
    text-transform: lowercase;
    padding: 0 10px;
    color: var(--text-icons-gray-200);
    font-size: 10px;
}

.formcautions ul li:last-child {
    padding-right: 0;
}

.formcautions {
    float: right;
}

.formcautions ul {
    padding-left: 25px;
    margin: 0;
}
.formcautions ul li:nth-child(1) span:first-child {
    background-color: var(--text-icons-blue-500);
}

.makesqaure {
    width: 8px;
    height: 8px;
    background: var(--text-icons-orange-500);
    display: inline-block;
    margin-right: 3px;
    border-radius: 50%;
}

.makesqaure.lockedthis {
    background: var(--text-icons-gray-200);
}

.form-group.activeborder .form-control {
    border-left: 5px solid var(--text-icons-orange-500) !important;
}

.form-group.inactiveborder {
    cursor: not-allowed;
}

.form-group.inactiveborder .form-control {
    border-left: 5px solid var(--text-icons-gray-200) !important;
    color: var(--text-icons-gray-200);
}

.form-group.inactiveborder.ftColor .form-control {
    color: var(--text-icons-gray-300);
}

.form-group.inactiveborder .selects > div > div > div {
    color: var(--text-icons-gray-200) !important;
}

.form-group.inactiveborder.ftColor .form-control {
    color: var(--text-icons-gray-300);
}

.form-group.ftColor .form-control {
    color: var(--text-icons-gray-300);
}

.verifylabel {
    float: right;
    color: var(--text-icons-green-500);
    text-transform: uppercase;
    font-size: 9px;
}

.form-group.haveaddon {
    position: relative;
}

.card-body .form-group.inactiveborder label {
    color: var(--text-icons-gray-200);
}

.card-body .form-group label {
    color: var(--text-icons-gray-200);
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    margin: 0 0 3px;
}

.form-group.activeborder .form-control {
    border-left: 5px solid var(--text-icons-orange-500) !important;
}

.card-body .form-group .form-control {
    background: var(--Buttons-All-Platforms-Gray-500);
    border-color: transparent;
    border-radius: 5px;
    border-width: 1px;
    color: var(--text-icons-gray-100);
    min-height: 40px;
    width: 100%;
}

.form-group input.form-control[disabled] {
    color: var(--text-icons-gray-200);
}

.form-group.has-description input,
.form-group.has-description textarea {
    float: left;
    width: calc(100% - 42px) !important;
    border-radius: 5px 0px 0px 5px !important;
}

.form-group .below-description {
    clear: both;
    text-align: right;
    font-size: 10px;
}

.form-group {
    position: relative;
}

.form-group .right-button {
    float: right;
    color: var(--text-icons-gray-100);
    height: 100%;
    width: 40px;
    border: 0;
    background: var(--Buttons-All-Platforms-Gray-500);
    font-size: 20px;
}

/* #endregion */

.categries-grid-container {
    min-width: 395px;
    height: 402px;
    /* border: 1px solid red; */
    padding: 8px;
    background-color: var(--backgrounds-lines-n-400);
    width: 100%;
}
.categries-grid-container p {
    margin-bottom: 0px;
}
.categries-grid-row1 {
    /* border: 1px solid green; */
    height: 174px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: var(--backgrounds-lines-n-500);
}
.categries-grid-row1 h2 {
    font-weight: 400;
    font-size: 32px;
    white-space: nowrap;
    color: #00aeef;
}
.categries-grid-row2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 14px;
}
.categries-grid-row2 p {
    font-size: 10px;
    font-weight: 500;
    color: var(--Buttons-All-Platforms-Gray-100);
    /* line-height: 5px; */
    letter-spacing: 1.25px;
    margin-bottom: 0;
}
.categries-grid-row2 p span {
    color: #00aeef;
}
.categries-grid-row2 .categries-grid-row-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
.categries-grid-row2 .categries-grid-row-icons img {
    width: 12.89px;
    margin-top: -11px;
}
.Pencil-color {
    width: 16px;
    height: 16px;
    color: white;
}
.categries-grid-row3 {
    margin-top: 8px;
}
.categries-grid-row3 p {
    font-size: 10px;
    font-weight: 500;
    color: var(--Buttons-All-Platforms-Gray-100);
    /* line-height: 5px; */
    letter-spacing: 1.25px;
    margin-bottom: 0;
}
.categries-grid-row3 p span {
    color: #00aeef;
}
.categries-grid-row4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 13px;
}
.categries-grid-row4 p {
    font-size: 8px;
    font-weight: 500;
    letter-spacing: 1.25px;
    color: var(--Buttons-All-Platforms-Gray-100);
    margin-bottom: 0px;
}

.categries-grid-row4 .hr {
    width: 100%;
    margin-left: 15px;
    /* border: 0.5px solid red; */
    height: 1px;
    background-color: #3c485a;
}
.categries-grid-row5 {
    margin-top: 16px;
}
.categries-grid-row5 p {
    font-size: 10px;
    font-weight: 400;
    color: #00aeef;
    margin-bottom: 0px;
}
.categries-grid-row6 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
}
.categries-grid-row6 p {
    font-size: 8px;
    font-weight: 500;
    letter-spacing: 1.25px;
    color: var(--text-icons-gray-100);
    margin-bottom: 0px;
    white-space: nowrap;
}

.categries-grid-row6 .hr {
    width: 100%;
    margin-left: 15px;
    /* border: 0.5px solid red; */
    height: 1px;
    background-color: #3c485a;
}
.categries-grid-row7 {
    display: flex;
    gap: 8px;
    margin-top: 16px;
}

.categries-grid-row7 .category-tag-container {
    height: 22px;
    background-color: #0155b3;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.categries-grid-row7 .category-tag-container p {
    color: var(--text-icons-gray-100);
    font-size: 12px;
    margin-bottom: 0px;
}
.categries-grid-row7 .category-tag-container div {
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.categries-grid-row7 .category-tag-container div i {
    color: var(--text-icons-gray-100);

    font-size: 12px;
}
.categries-grid-row7 .category-tag-container-item-number {
    width: 29px;
    height: 22px;
    background-color: var(--backgrounds-lines-n-500);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.categries-grid-row7 .category-tag-container-item-number p {
    font-size: 12px;
    font-weight: 400;
    color: var(--text-icons-gray-100);
    margin-bottom: 0px;
}
.categries-grid-row8 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
}

.categries-grid-row8 button {
    border: none;
    outline: none;
    width: 49%;
    height: 35px;
    border-radius: 4px;
    font-size: 10px;
    letter-spacing: 1.25px;
    color: var(--Buttons-All-Platforms-Gray-100);
    background-color: #10141b;
}
.scroll-bar-container::-webkit-scrollbar-button {
    display: none;
}

.tag-icon {
    height: 35px;
    width: 35px;
    position: absolute;
    top: 16px;
    right: 16px;
}

/* .app {
    width: 100%;
    height: 100%;
} */

.year {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.month {
    margin: 5px 5px 15px 5px;
}

.month-name {
    color: #ccbe88;
    font-weight: bold;
}

.day {
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
}

.date {
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 50px;
    border: none;
    outline: none;
}

.date.in-month:hover {
    cursor: pointer;
    background: #ccbe88;
}

.today {
    background: #ccbe88;
}

.date.prev-month,
.date.next-month {
    color: var(--backgrounds-lines-n-200);
}
.rbc-btn-group:last-child {
    display: none !important;
}

.rbc-calendar {
    max-width: 100%;
    /* min-width: 1337px; */
    min-height: 98% !important;
    background-color: #141922;
    overflow-y: scroll;
    /* padding-right: 5px; */
}
.rbc-calendar::-webkit-scrollbar {
    width: 3px;
    /* border: 1px solid red; */
}
.rbc-calendar::-webkit-scrollbar-thumb {
    background-color: #262e3e !important;
    border-radius: 5px;
}
.rbc-off-range-bg {
    background: none !important;
}
.rbc-button-link {
    color: #ffffff !important;
}

.rbc-header {
    display: flex;
    color: #ff9900;
    /* padding: 32px !important; */
    height: 39px;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #262e3e !important;
    border-bottom: 1px solid #262e3e !important;
    background-color: #1e2631;
}

.rbc-header span {
    font-weight: 400;
    font-size: 14px;
}
.rbc-event {
    /* width: 95% !important; */
    text-align: center !important;
    margin: 0px 4px !important;
    margin-top: 2px !important;
    background: none !important;
    border: none !important;
    outline: none !important;
}

.rbc-row-segment {
    display: flex;
    justify-content: center;
    align-items: center;
}
.rbc-today {
    background: none !important;
}
.rbc-date-cell {
    text-align: center !important;
}
.rbc-date-cell {
    height: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1e2631;
    border-bottom: 1px solid #30373f !important;
    border-left: 1px solid #30373f !important;
}

.toolbar-element-one-container {
    width: 213px;
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    /* margin-left: 24px; */
    align-items: center;
}
.calendar-toolbar-label {
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}
.toolbar-container {
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    height: 68px;
    align-items: center;
    padding-bottom: 24px;
    background-color: #10141b;
}
.calendar-next-btn,
.calendar-back-btn {
    background-color: var(--Buttons-All-Platforms-Gray-300);
    /* padding: 6px 13px; */
    border-radius: 4px;
    width: 40px;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.calendar-next-btn i,
.calendar-back-btn i {
    font-size: 8px;
}
.toollbar-element-two-container {
    width: 140px;
    height: 37px;
    /* border: 1px solid red; */
    background-color: var(--Buttons-All-Platforms-Gray-300);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
}
.toollbar-element-two-container p {
    font-size: 12px;
    font-weight: 700;
    color: #00aeef;
    margin-bottom: 0px !important;
}
.toollbar-element-three-container,
.toollbar-element-four-container,
.toollbar-element-five-container {
    height: 37px;
    width: 70px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
}
.toollbar-element-three-container p,
.toollbar-element-four-container p,
.toollbar-element-five-container p {
    margin-bottom: 0px;
}
.toollbar-element-three-container {
    background-color: #0060cc1a;
}
.toollbar-element-four-container {
    background-color: #00bb001a;
}
.toollbar-element-five-container {
    background-color: #ff36361a;
}
.view-dropdown select {
    border: none;
    width: 130px;
    background-color: var(--Buttons-All-Platforms-Gray-300);
    height: 37px;
    color: #00bb00;
    padding: 0px 20px;
    border-radius: 4px;
    outline: none;
}
.view-dropdown {
    padding-right: 20px;
    background-color: var(--Buttons-All-Platforms-Gray-300);
    border-radius: 4px;
}

.rbc-month-view {
    border: 0px solid var(--Buttons-All-Platforms-Gray-300) !important;
    min-height: 759px !important;
    border-radius: 4px;
    background-color: #141922;
}
.rbc-day-bg {
    border-left: 1px solid var(--backgrounds-lines-n-200) !important;
    /* height: 100px !important;
    width: 100px !important; */
    z-index: 99 !important;
}

.rbc-month-row {
    border-top: 1px solid var(--backgrounds-lines-n-200) !important;
}
.rbc-show-more {
    background-color: #5858581a !important;
    width: 90%;
    padding: 4px 8px !important;
    border-radius: 5px;
    margin-top: 3px !important;
}
.show-more-btn {
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    justify-content: space-between;
}
.calendar-component-wrapper {
    display: flex;
    justify-content: space-between;

    position: relative;
    /* border: 1px solid red; */
}
.orders-container {
    max-width: 100%;
    min-width: 292px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    gap: 8px;
    /* border: 1px solid red; */
    background-color: #141922;
    margin-left: 12px;
    height: 100%;
    /* min-height: 824px; */
    overflow-y: auto;
    position: relative;
}
.orders-container::-webkit-scrollbar {
    width: 3px;
}
.orders-container::-webkit-scrollbar-thumb {
    height: 12px !important;
    background-color: var(--backgrounds-lines-n-200) !important;
    border-radius: 5px;
}
.rbc-today {
    border: 1px solid var(--text-icons-gray-100) !important;
    position: relative;
    z-index: 99;
}
.date-popup > div {
    background-color: #141922 !important;
}
.ant-picker-cell-inner {
    color: white !important;
    background: none !important;
}
.ant-picker-header-view {
    color: white;
}
.ant-picker-dropdown .ant-picker-header button {
    color: white;
}
.ant-picker-cell-selected div {
    border-radius: 50% !important;
    width: 49px !important;
    height: 50px !important;
    border: 1px solid #ffffff;
    line-height: 46px !important;
}

/* #endregion */

.first-portion-container {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
}
.first-portion-input-one {
    width: 100%;
}
.first-portion-input-one input {
    color: var(--Buttons-All-Platforms-Confirm-Outline);
}

.first-portion-second-input {
    width: 100%;
}
.first-portion-second-input textarea {
    color: var(--Buttons-All-Platforms-Confirm-Outline) !important;
    min-height: 76px !important;
}
.generate-btn-container {
    text-align: right;
    padding-right: 15px !important;
}
.generate-btn-container p {
    font-size: 8px;
    font-weight: 500 !important;
    margin-top: -10px;
    color: var(--text-icons-blue-500);
    letter-spacing: 1.25%;
}
.first-portion-third-input label {
    color: var(--Buttons-All-Platforms-Gray-100) !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    padding-bottom: 24px !important;
}
.first-portion-third-input .form-control {
    background-color: var(--backgrounds-lines-n-300) !important;
    border: 1px solid var(--backgrounds-lines-n-100) !important;
}

/* .form-group.activeborder .form-control:nth-child(2) {
    border-left: 5px solid var(--backgrounds-lines-n-500) !important;
} */

.first-portion-third-input .form-control:nth-child(1) {
    border-left: 1px solid var(--backgrounds-lines-n-100) !important;
}

.cardContiner-main {
    width: 1195px;
    /* height: 80px; */
    display: grid;
    gap: 24px;
    /* padding: 0px 0px 22px 0px; */
    background: var(--Buttons-All-Platforms-Gray-300);
    border-radius: 4px;
    color: #ffffff;
    padding-bottom: 40px;
}
.cardContainer-first-div {
    width: 1195px;
    height: 16px;
    padding: 23px 0px 23px 0px;
    border-bottom: 1px solid #3c485a;
    display: flex;
    gap: 24px;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}
.cardContainer-body {
    display: flex;
    gap: 10px;
    margin-right: 12px;
}
.account-main {
    margin-left: 12px;
}
.account-user {
    font-family: "Roboto";
    line-height: 16.41px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.5%;
    color: #ffffff;
}
.cirlcle-1 {
    width: 6px;
    height: 6px;
    background-color: var(--text-icons-blue-500);
    border-radius: 50%;
}
.cardContainer-child-1 {
    display: flex;
    align-items: center;
    gap: 4px;
}

.cirlcle-2 {
    width: 6px;
    height: 6px;
    background-color: var(--Buttons-Orange-Outline);
    border-radius: 50%;
}
.cardContainer-child-2 {
    display: flex;
    align-items: center;
    gap: 4px;
}
.cirlcle-3 {
    width: 6px;
    height: 6px;
    background-color: var(--text-icons-gray-300);
    border-radius: 50%;
}
.cardContainer-child-3 {
    display: flex;
    align-items: center;
    gap: 4px;
}

/* box*/

.CardBox {
    max-width: 360px;
    min-width: 360px;
    /* height: 222px; */
    background: var(--Buttons-All-Platforms-Gray-300);
    border: 1px solid var(--backgrounds-lines-n-200);
    border-radius: 4px;
}

.CardBox-heading {
    width: 296px;
    font-size: 10px;
    line-height: 5px;
    font-weight: 500;
    letter-spacing: 1.25%;
    color: #00aeef;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 19px;
    margin-left: 32px;
}
.CardBox-image-header {
    width: 100%;
    padding-top: 32px;
}

.CardBox-container-hr {
    width: 296px;
    height: 1px;
    margin-top: 16px;
    margin-left: 32px;
    border: 1px;
    background: var(--backgrounds-lines-n-200);
}
.CardBox-container-body {
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 296px;
    margin-left: 32px;
}
.CardBox-title {
    height: 21px;
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.09px;
    margin-top: 16px;
    margin-bottom: 16px;
}
.CardBox-pregraf {
    width: 296px;
    height: 42px;
    font-size: 12px;
    align-items: center;
    font-weight: 400;
    text-align: center;
    line-height: 14.04px;
    color: var(--text-icons-gray-100);
    margin-bottom: 32px;
}
.CardBox-buttons {
    width: 296px;
    gap: 10px;
    display: flex;
    margin-bottom: 20px;
}
.CardBox-button-a {
    width: 143px;
    height: 35px;
    background: var(--backgrounds-lines-n-500);
    border: none;
    color: var(--Buttons-All-Platforms-Gray-100);
    border-radius: 4px;
    outline: none;
}
.CardBox-button-b {
    width: 143px;
    height: 35px;
    color: var(--Buttons-All-Platforms-Gray-100);
    border-radius: 4px;
    /* background: var(--Buttons-All-Platforms-Confirm-Active); */
    background-color: #008800;
    border: none;
    outline: none;
}
.CardBox-button-b:hover {
    width: 143px;
    height: 35px;
    color: var(--Buttons-All-Platforms-Gray-100);
    border-radius: 4px;
    /* background: var(--Buttons-All-Platforms-Confirm-Active); */
    background-color: #009900;
    border: none;
    outline: none;
}

.dbox {
    color: red;
    margin-top: 24px;
}
.ready-delete {
    width: 232px;
    height: 21px;
    font-size: 18px;
    font-weight: 750;
    color: #ffffff;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin: 16px 28px 16px 28px;
    line-height: 21.04px;
}
.Delete-CardBox-pregraf {
    width: 296px;
    font-size: 12px;
    align-items: center;
    font-weight: 400;
    text-align: center;
    line-height: 21.04px;
    color: #ffffff;
    margin-bottom: 32px;
}

.button-marginb {
    margin-bottom: 24px;
}
.CardBox-container-hr2 {
    width: 296px;
    height: 1px;
    margin-top: 25px;
    margin-left: 32px;
    border: 1px;
    background: var(--backgrounds-lines-n-200);
}

/* SaveBox */

.saveBox {
    color: var(--Buttons-All-Platforms-Confirm-Active);
    font-family: "Raboto";
}
.account-user {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
}

.cardContainer-child-1 p,
.cardContainer-child-2 p,
.cardContainer-child-3 p {
    margin: 0;
    font-size: 10px;
    color: var(--text-icons-gray-200);
}
.selected-day {
    border: 1px solid var(--App-Accent-Outline) !important;
    z-index: 99;
}
.not-selected {
    border: none !important;
}
.form-container-main {
    height: 48px;
}
.form-container-first {
    display: flex;
    width: 357px;
    height: 12px;
    padding: -1px 5px 0px 5px;
    margin-bottom: 5px;
}
.form-container-first > :nth-child(2) {
    width: 20px;
    height: 12px;
    color: #7c868b;
    font-size: 10px;
    font-weight: 400;
    line-height: 11.72px;
    margin-left: 4px;
}
.form-container-first > i {
    width: 8px;
    color: #7c868b;
    height: 8px;
    font-size: 10px;
    font-weight: 400;
    line-height: 11.72px;
}
.form-container-second > input {
    height: 36px;
    border: none;
    outline: none;
    border-radius: 4px 4px 4px 4px;
    padding: 6px 12px 6px 12px;
    background-color: #4a576d;
}
.form-container-second > input[type="text"]::placeholder {
    height: 14px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.06px;
}
.form-container-third > small {
    width: 62px;
    height: 9px;
    font-size: 8px;
    font-weight: 500;
    line-height: 9.38px;
    letter-spacing: 1.25%;
    margin-right: 5px;
}
.form-container-third {
    height: 9px;
    padding: 0px 5px 0px 5px;
    align-items: end;
    text-align: end;
}

.form-container-row2 input {
    width: 362px;
    height: 36px;
    border: none;
    outline: none;
    border-radius: 4px 4px 4px 4px;
    padding: 6px 12px 6px 12px;
    background-color: #4a576d;
    border: 1px solid rgb(13, 173, 201);
}
.form-container-first.input-filled span,
.form-container-third.input-filled small,
.form-container-first.input-filled > i,
.form-container-second.input-filled input {
    color: red;
    border-color: red;
}
.form-container-second {
    position: relative;
}
.clear-btn {
    border: none;
    outline: none;
    background-color: transparent;
    color: white;
    position: absolute;
    right: 13px;
    top: 7px;
}
.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.dot {
    width: 8px;
    height: 8px;
    background-color: rgb(2, 186, 0);
    border-radius: 50%;
    margin: 0 5px;
    animation: moveUpDown 1s infinite ease-in-out;
}

.dot1 {
    animation-delay: 0s;
}

.dot2 {
    animation-delay: 0.2s;
}

.dot3 {
    animation-delay: 0.4s;
}

.dot4 {
    animation-delay: 0.6s;
}

.dot5 {
    animation-delay: 0.8s;
}

@keyframes moveUpDown {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}

.invite-input-two {
    position: relative;
}
.invite-email-input {
    border: none;
    outline: none;
    max-width: 395px;
    width: 100%;
    min-height: 38px;
    border-left: 5px solid #ff9900;
    border-radius: 4px;
    color: #00bb00 !important;
    background-color: #10141b;
    padding-left: 62px;
}
.line {
    border: 0.5px solid #3c485a;
    height: 38px;
    top: 23.3px;
    left: 54px;
}
.email-icon {
    left: 23px;
    top: 34px;
    font-size: 16px;
    color: #d1d1d1;
}

.line,
.email-icon {
    position: absolute;
    z-index: 99;
}
.invite-table {
    margin-left: -30px;
    margin-right: -30px;
}

.row-one-first-container {
    display: flex;
    gap: 24px;
    padding: 0px 30px;
}
.image-carousel-container {
    width: 74%;
    border: 1px solid var(--backgrounds-lines-n-100);
    border-radius: 4px;
    position: relative;
}
.image-carousel-wrapper {
    display: flex;
    gap: 8px;
    overflow-x: scroll;
    margin: 12px 8px;
}
.image-carousel-wrapper::-webkit-scrollbar {
    display: none;
}

.image-carousel-wrapper div {
    height: 274px;
    /* min-width: 173px; */
    /* max-width: 173px; */
    background-color: var(--backgrounds-lines-n-300);
    border-radius: 9px;
}
.row-one-first-container .inputs-container {
    display: flex;
    flex-direction: column;
    gap: 26px;
    width: 25%;
}
.row-one-first-container .inputs-container .edit-select-base {
    position: absolute;
    font-size: 8px;
    font-weight: 500;
    color: var(--text-icons-blue-500);
    right: 2px;
    bottom: -15px;
}
.item-variation-wrapper-container {
    margin: 13px;
}
.item-variation-wrapper-container h5 {
    margin: 24px 0px;
    font-size: 14px;
    font-weight: 500;
    color: var(--Buttons-All-Platforms-Gray-100);
}
.item-variation-container {
    border: 1px solid var(--backgrounds-lines-n-100);
    width: 257px;
    min-height: 271px;
    border-radius: 4px;
    padding: 16px;
    background-color: var(--backgrounds-lines-n-300);
}
.item-variation-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.item-variation-header div {
    /* border: 1px solid red; */
    background-color: lightgreen;
    width: 82px;
    height: 38px;
    border-radius: 4px;
    margin-right: 14px;
}
.item-variation-header h3 {
    font-size: 14px;
    font-weight: 500;
    margin: 0px;
    color: #ffffff;
}
.item-variation-headings {
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
}
.item-variation-headings div {
    border: 0.5px solid var(--backgrounds-lines-n-100);
}
.item-variation-headings p {
    margin: 0px;
    padding: 0px;
    white-space: nowrap;
    font-size: 8px;
    font-weight: 500;
}
.item-variation-content-container {
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.item-variation-content-wrapper {
    display: flex;
    justify-content: space-between;
}
.item-variation-content-wrapper p {
    margin: 0px;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
}
.item-variation-component-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.carousel-image-container {
    background-image: url(./assets/images/carouselBgImg.jpg);
    background-size: cover;
    width: fit-content;
}
.product-info-container {
    display: grid;
    /* flex-wrap: wrap; */
    grid-template-columns: repeat(4, 1fr); /* Start with 4 columns */
    padding: 10px;
    gap: 24px;
}
/* Media query for when container width is less than or equal to 800px */
@media (max-width: 1400px) {
    .product-info-container {
        grid-template-columns: repeat(3, 1fr); /* Change to 3 columns */
    }
}

/* Media query for when container width is less than or equal to 600px */
@media (max-width: 1200px) {
    .product-info-container {
        grid-template-columns: repeat(2, 1fr); /* Change to 2 columns */
    }
}

/* Media query for when container width is less than or equal to 400px */
@media (max-width: 700px) {
    .product-info-container {
        grid-template-columns: 1fr; /* Change to 1 column */
    }
}
@media only screen and (max-width: 1024px) {
    .mobileBackground {
        overflow: auto !important;
        height: 71vh !important;
        padding-top: 300px !important;
    }
}
/* @media only screen and (max-width: 1024px) {
    .Frame3132624 {
        height: 50vh !important;
        overflow: scroll !important;
        padding-top: 400px !important;
    }
    .frame-12 {
        margin-top: 60px !important;
    }
} */

.btns {
    position: absolute;
    top: 142px;
}
.forward {
    left: 10px;
}
.backward {
    right: 10px;
}
.product-edit-btn-shawdow {
    position: absolute;
    height: 100%;
    width: 5px;
    top: 0px;
    box-shadow: 10px 10px 50px white;
}
.forward-shawdow {
    left: 1px;
}
.backward-shawdow {
    right: 1px;
}
.calender-loader {
    height: 100%;
}
.view-dropdown {
    width: 150px;
    height: 37px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 0px;
    position: relative;
    padding: 12px;
}
.ant-dropdown-menu {
    background-color: #141922 !important;
    position: relative;
    top: 10px;
    left: -10px;
    width: 120px;
}
.ant-dropdown-menu li span {
    color: #ffffff !important;
}
.ant-dropdown-menu li:disabled {
    background-color: red !important;
}
.ant-space-item {
    color: #00bb00;
}
/* StandardNotification.css */

.notification {
    width: 360px;
    height: 78px;
    display: flex;
    align-items: center;
    padding: 16px;
    border-radius: 4px;
    background-color: #141922;
}
/* .notification-main {
    background-color: rgb(2, 186, 0);
    width: 40px;
    height: 40px;
} */

.notification.error {
    background-color: #f8d7da;
    color: #721c24;
}

.notification.success {
    background-color: #141922;
    color: #155724;
}

.notification-icon {
    margin-right: 10px;
}

.notification-content {
    flex: 1;
}

.notification-message {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 8px;
    line-height: 14.06px;
}

.notification-details {
    font-size: 10px;
    font-weight: 400;
    line-height: 11.72px;
    color: #d1d1d1;
}

.notification-dismiss {
    background-color: transparent;
    border: none;
    font-size: 12px;
    cursor: pointer;
}
.calendar-card-component {
    width: 275px;
}
/* ______________ */

.dropdown {
    position: relative;
    color: #333;
    cursor: default;
}

.dropdown .arrow {
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: " ";
    display: block;
    height: 0;
    margin-top: 0.3rem;
    position: absolute;
    right: 10px;
    top: 14px;
    width: 0;
}

.dropdown .arrow.open {
    border-color: transparent transparent #999;
    border-width: 0 5px 5px;
}

/* .dropdown .selected-value input {
    line-height: 1.5;
    font-size: 1rem;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 2px;
    box-sizing: border-box;
    cursor: default;
    outline: none;
    padding: 8px 52px 8px 10px;
    transition: all 200ms ease;
    width: 100%;
} */

.dropdown .options {
    display: none;
    background-color: #141922;

    /* border: 1px solid #ccc; */
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    margin-top: 5px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
}

.dropdown .options.open {
    display: block;
}

.dropdown .option {
    box-sizing: border-box;
    color: rgba(51, 51, 51, 0.8);
    cursor: pointer;
    display: block;
    padding: 8px 10px;
    display: flex;
    justify-content: space-between;
    color: #d1d1d1;
    margin: 10px;
    border-radius: 4px;
}

.dropdown .option.selected,
.dropdown .option:hover {
    background-color: #1e2631;
    color: #333;
}
.editable-dropdown {
    width: 100%;
    border-radius: 4px;
    /* border-left: 5px solid #262e3e !important; */
    background-color: #10141b !important;
    padding: 8px 52px 8px 10px;
    outline: none !important;
    border: none;
    color: #00bb00 !important;
}
.no-options-container {
    /* border: 1px solid red; */
    color: #d1d1d1;
    text-align: center;
    padding: 10px;
}
.nda-popup-container {
    width: 460px;
    /* height: 335px; */
    background-color: #1e2631;
    border-radius: 4px;
    position: absolute;
    z-index: 999999;
    padding-bottom: 16px;
}
.main-date-popup {
    margin-top: 16px;
    text-align: start;
}
.body-p2-popup {
    margin-top: 16px;
}
.body-p4-popup {
    margin-top: 16px;
}
.heading-popup {
    display: flex;
    padding-top: 20px;
}
.main-heading {
    padding-left: 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.41px;
    letter-spacing: 1.5%;
    margin-top: 2px;
    margin-bottom: 20px;
}
.icon-popup {
    margin-left: 152px;
}
.date-popup {
    color: #7c868b;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    font-family: Roboto;
    padding-left: 16px;
}
.body-popup {
    padding: 16px;
}
.body-popup p {
    /* color: #7c868b; */
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    font-family: Roboto;
    text-align: start;
}
.heading-popup {
    border-bottom: 1px solid #3c485a;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.footer-popup {
    text-align: center;
    /* margin-top: 12px; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-popup {
    text-align: center;
    padding-top: 7px;
    /* margin-bottom: 16px; */
}
.button-main-popup {
    border-radius: 4px;
    /* height: 35px; */
    background-color: #10141b;
    color: #4f5b6d;
    border: none;
    padding: 10px;
    font-size: 12px;
}

.checkbox-container label {
    cursor: pointer;
    display: flex;
}

.checkbox-container input[type="checkbox"] {
    cursor: pointer;
    opacity: 0;
    position: absolute;
}

.checkbox-container label::before {
    content: "";
    width: 16px;
    height: 16px;
    border-radius: 4px;
    margin-right: 0.5em;
    border: 1px solid #262e3e;
    background-color: #10141b;
}

.checkbox-container input[type="checkbox"]:checked + label::before {
    /* content: "\002714"; */
    background-image: url(./assets/icons/check.png);
    background-size: cover;
    background-color: #10141b;
    display: flex;
    justify-content: center;
    border: 1px solid #00bb00;
    align-items: center;
}
.ant-tooltip-inner {
    background-color: #10141b !important;
    font-size: 10px !important;
    font-weight: 500;
    padding: 8px !important;
    color: #d1d1d1;
}
.ant-tooltip-arrow::after {
    background-color: #10141b !important;
}
.terms-condition-container {
    width: 400px;
    padding: 16px;
    background-color: #121821;
    position: absolute;
    right: 0px;
    height: 100vh;
    overflow-y: scroll;
    z-index: 9999999999;
}

.terms-main-condition h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 18.75px;
    font-family: "Roboto" !important;
    margin: 14px 0px;
}
.terms-main-condition p {
    font-weight: 400;
    letter-spacing: 0.25% !important;
    font-size: 14px;
    line-height: 16.41px;
    font-family: "Roboto" !important;
}
.custom-tooltip .ant-tooltip-inner {
    font-size: 10px !important;
}
