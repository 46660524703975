.unit-sold-container {
    min-width: 400px;
    padding: 16px;
    height: 370px;
    margin-top: 24px;
    /* margin-bottom: 24px; */
    background-color: #1e2631;
    border-radius: 4px;
}
.unit-sold-main-data {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.unit-sold-single-data {
    display: flex;
    padding: 4px;
    justify-content: space-between;
    align-items: center;
    height: 49px;
    border-radius: 4px;
}
.unit-sold-single-data:nth-child(even) {
    background-color: #1a222d;
}

.unit-sold-img-portion {
    display: flex;
    align-items: center;
    gap: 8px;
}
.unit-sold-img-holder {
    width: 30px;
    height: 41px;
}
.unit-sold-single-brand-data p:nth-child(1) {
    font-size: 12px;
    font-weight: 400;
    color: #d1d1d1;
}
.unit-sold-single-brand-data p:nth-child(2) {
    font-size: 12px;
    font-weight: 400;
    color: #7c868b;
}
.unit-sold-single-last-portion {
    display: flex;
    flex-direction: column;
    text-align: right;
}

.unit-sold-single-last-portion p:nth-child(1) {
    font-size: 12px;
    font-weight: 400;
    color: #d1d1d1;
}
.unit-sold-single-last-portion p:nth-child(2) {
    font-size: 12px;
    font-weight: 400;
    color: #7c868b;
}
